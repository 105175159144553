
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";

@Component
export default class DialogBase extends Vue {
    @PropSync("dialogShow", { type: Boolean || null })
    syncDialogShow!: null;

    @Prop({ type: Number, required: false, default: 400 })
    width!: number;
    @Prop({ type: Boolean, required: false, default: false })
    dialogSettings!: boolean;
}
